import React from 'react';

const SvgInliner = ({ svgContent, className }) => {
  if (svgContent === null) {
    console.log('SVG data was null. Please check strapi.');
    return <div />;
  } else {
    return (
      <div
        className={className}
        dangerouslySetInnerHTML={{ __html: svgContent }}
      />
    );
  }
};

export default SvgInliner;
