import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import Footer from './components/global/Footer';
import Header from './components/global/Header';
import Seo from './components/global/Seo';
import './Layout.scss';

const Layout = ({ children, footerBg, title, seoImage }) => {
  return (
    <Fragment>
      <Header />
      <Seo pageTitle={title} image={seoImage} />
      <main id={`main`} tabIndex={-1}>
        {children}
      </main>
      <Footer theme={footerBg} />
    </Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
