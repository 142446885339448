import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';
import {
  getTheApp,
  homeLink,
  logoNavDrawer,
  mainSections,
  menuItems,
  menuWrapper,
  navDrawerBg,
  sectionsContent,
  show,
  svgWrapper,
} from './NavDrawer.module.scss';
import SvgInliner from './SvgInliner';

const NavDrawer = ({ navDrawerOpen, setNavDrawerOpen, sections, logo }) => {
  const navRef = useRef(null); // A wrapper so we can use querySelectorAll

  useEffect(() => {
    if (navDrawerOpen) {
      disablePageScroll(navRef.current); // continue to allow scrolling on navRef.current
    } else {
      enablePageScroll(navRef.current);
    }
  }, [navDrawerOpen]);

  const closeIfTabAway = () => {
    setTimeout(() => {
      if (!navRef.current?.contains(document.activeElement)) {
        setNavDrawerOpen(false);
      }
    }, 200); // setTimeout necessary bc onBlur always returns <body> otherwise
  };

  return (
    <div
      className={` ${menuWrapper} ${navDrawerOpen ? show : ''}`}
      ref={navRef}
      data-scroll-lock-scrollable
    >
      <div className={`${navDrawerBg} ${navDrawerOpen ? '' : 'hide'}`}>
        <div className={`${logoNavDrawer}`}>
          <Link className={`${homeLink}`} to={`/`} onBlur={closeIfTabAway}>
            <p className={`srOnly`}>Free and Equal Homepage</p>
            <SvgInliner
              svgContent={logo.localFile.svgData}
              className={`${svgWrapper}`}
            />
          </Link>
        </div>
      </div>
      <div className={`${menuItems} ${navDrawerOpen ? '' : 'hide'}`}>
        {sections.map(({ heading, pages }, index) => {
          return (
            <div className={`${sectionsContent} pb48`} key={index}>
              <h3 className={`h4 ${mainSections}`}>{heading}</h3>
              <ul>
                {pages.map(({ slug, title }) => (
                  <li key={slug} className={`menuLink`}>
                    <Link
                      to={`/${slug}/`}
                      onClick={() => setNavDrawerOpen(false)}
                      onBlur={closeIfTabAway}
                      activeClassName={`activePage`}
                    >
                      {title}
                    </Link>
                  </li>
                ))}
                {heading === 'Visit' && (
                  <li>
                    <a
                      href="https://www.freeandequalproject.com/"
                      className={`navHand ${getTheApp} `}
                    >
                      Get the App
                    </a>
                  </li>
                )}
              </ul>
            </div>
          );
        })}
      </div>
    </div>
  );
};

NavDrawer.propTypes = {
  navDrawerOpen: PropTypes.bool,
  toggleNavDrawer: PropTypes.func,
  links: PropTypes.array,
};

export default NavDrawer;
