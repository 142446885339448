import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import parse from 'html-react-parser';
import React from 'react';
import {
  credits,
  footerContainer,
  footerLogos,
  freeLogo,
  homeLink,
  sitemap,
  sitemapBg,
} from './Footer.module.scss';
import SvgInliner from './SvgInliner';

const Footer = ({ theme }) => {
  const data = useStaticQuery(graphql`
    {
      strapiHomepage {
        footer_linked_logos {
          link
          logo {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 60)
              }
            }
          }
          title
        }
        footer_credit
        free_equal_logo {
          localFile {
            svgData
          }
        }
        sections {
          heading
          linksTo
        }
      }
      allStrapiChapters(
        sort: { fields: order, order: ASC }
        filter: { isSubChapter: { eq: false } }
      ) {
        nodes {
          title
          slug
        }
      }
      allStrapiAbout {
        nodes {
          slug
          title
        }
      }
      allStrapiVisitPages(sort: { fields: order, order: ASC }) {
        nodes {
          slug
          title
        }
      }
    }
  `);

  const { footer_linked_logos, footer_credit, free_equal_logo } =
    data.strapiHomepage;
  let siteContent = data.strapiHomepage.sections;
  siteContent.map((section) => {
    section.pages = data[`allStrapi${section.linksTo}`].nodes;
    return section;
  });
  if (theme === null || theme === undefined) theme = 'Light';

  return (
    <footer className={`${footerContainer} bg${theme}`}>
      <div className={`${sitemapBg}`}></div>
      <div className={`${sitemap} textColorBlack`}>
        <Link to={`/`} className={`${homeLink}`}>
          <p className={`srOnly`}>Free and Equal Homepage</p>
          <SvgInliner
            className={`${freeLogo}`}
            svgContent={free_equal_logo.localFile.svgData}
          />
        </Link>

        {siteContent.map(({ heading, pages }, index) => {
          return (
            <ul key={index}>
              <li>
                <span className={`nav`}>{heading}</span>
                <ul>
                  {pages.map(({ slug, title }) => (
                    <li key={slug} className={`mt16 footer`}>
                      <Link to={`/${slug}/`}>{title}</Link>
                    </li>
                  ))}
                </ul>
              </li>
            </ul>
          );
        })}
      </div>

      <div className={`pb40 ${credits} textColorBlack`}>
        {footer_linked_logos.map(({ link, logo, title }, index) => {
          return (
            <a
              href={link}
              target={'_blank'}
              rel="noopener noreferrer"
              className={`${footerLogos}`}
              key={index}
            >
              <GatsbyImage alt={title} image={getImage(logo.localFile)} />
            </a>
          );
        })}
        <div className={`textCenter footerCredit`}>
          {parse(footer_credit)}
          <span className={`block mt32 footerCredit`}>
            © Copyright {new Date().getFullYear()}
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
