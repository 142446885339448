import React from 'react';

const SmallRedCircle = () => {
  return (
    <svg viewBox="0 0 254 67" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M109.272 5.74635C91.8071 5.7439 75.411 9.38971 59.2148 13.9555C41.342 18.9939 12.6887 26.9629 3.69145 40.7706C-4.74909 53.724 26.1381 58.9146 36.8895 60.4897C86.6407 67.7783 139.919 63.1519 188.048 53.468C209.764 49.0984 243.941 40.9439 250.888 22.3427C256.453 7.43985 219.519 4.99367 208.272 4.1746C157.499 0.477067 105.283 4.48933 56.2368 13.5443C40.818 16.391 19.7363 19.3448 7.14846 27.1469C-2.21279 32.9492 14.2535 35.1185 18.8892 36.0978"
        stroke="#E56C6C"
        strokeWidth="4"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default SmallRedCircle;
