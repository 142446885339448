import { graphql, Link, useStaticQuery } from 'gatsby';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import React, { useEffect, useRef, useState } from 'react';
import Hamburger from './Hamburger';
import {
  getTheApp,
  header,
  hide,
  hideNavBg,
  logoHome,
  menu,
  navBarContent,
  navHeading,
  skipLink,
} from './Header.module.scss';
import NavDrawer from './NavDrawer';
import SvgInliner from './SvgInliner';
gsap.registerPlugin(ScrollTrigger);

const Header = () => {
  const data = useStaticQuery(graphql`
    {
      strapiHomepage {
        free_equal_logo {
          localFile {
            svgData
          }
        }
        sections {
          heading
          linksTo
        }
      }
      allStrapiAbout {
        nodes {
          title
          slug
        }
      }
      allStrapiVisitPages(sort: { fields: order, order: ASC }) {
        nodes {
          title
          slug
        }
      }
      allStrapiChapters(
        sort: { fields: order, order: ASC }
        filter: { isSubChapter: { eq: false } }
      ) {
        nodes {
          title
          slug
        }
      }
    }
  `);

  const { free_equal_logo } = data.strapiHomepage;
  let sections = data.strapiHomepage.sections;
  sections.map((section) => {
    section.pages = data[`allStrapi${section.linksTo}`].nodes;
    return section;
  });

  const navDrawerRef = useRef(null);
  const [navDrawerOpen, setNavDrawerOpen] = useState(false);

  const toggleNavDrawer = () => {
    setNavDrawerOpen(!navDrawerOpen);
  };

  useEffect(() => {
    if (navDrawerOpen) {
      // Update the aria-expanded attribute
      navDrawerRef.current.setAttribute('aria-expanded', true);
      document.body.style.overflowY = 'hidden';
    } else if (!navDrawerOpen) {
      // Update the aria-expanded attribute
      navDrawerRef.current.setAttribute('aria-expanded', false);
      document.body.style.overflowY = 'unset';
    }
  }, [navDrawerRef, navDrawerOpen]);

  const focusMainContent = () => {
    document.querySelector('#main').focus();
  };

  const navRef = useRef(null);

  useEffect(() => {
    const showAnim = gsap
      .from(navRef.current, {
        yPercent: -500,
        paused: true,
        duration: 0.3,
      })
      .progress(1);

    const st = ScrollTrigger.create({
      trigger: 'body',
      start: 'top+=80px top',
      end: 'bottom bottom',
      //markers: true,
      scrub: 2,
      onUpdate: (self) => {
        if (self.direction === -1 || navDrawerOpen) {
          showAnim.play();
        } else {
          showAnim.reverse();
        }
      },
    });

    return () => {
      showAnim.kill();
      st.kill();
    };
  }, [navDrawerOpen]);

  return (
    <header className={`${header}`} data-scroll-lock-fill-gap>
      <Link
        to="#main"
        className={`nav srOnly textColorWhite ${skipLink}`}
        onClick={focusMainContent}
      >
        Skip to Content
      </Link>
      <nav aria-labelledby={`#navHeader`}>
        <h2 className={`srOnly`} id={`navHeader`}>
          Site Navigation
        </h2>
        <div
          className={`${navBarContent} ${navDrawerOpen ? hideNavBg : ''}`}
          ref={navRef}
          data-scroll-lock-fill-gap
        >
          <Link
            className={`${logoHome} ${navDrawerOpen ? 'hide' : ''}`}
            to={`/`}
          >
            <p className={`srOnly`}>Free and Equal Homepage</p>
            <SvgInliner svgContent={free_equal_logo.localFile.svgData} />
          </Link>
          <div className={`${menu} ${navDrawerOpen ? hide : ''}`}>
            {sections.map(({ heading, pages }, index) => {
              return (
                <Link
                  className={`${navHeading} ${navDrawerOpen ? 'hide' : ''}`}
                  to={`/${pages[0]?.slug}/`}
                  key={index}
                >
                  {heading}
                </Link>
              );
            })}
            <a
              href="https://www.freeandequalproject.com/"
              className={`navHand ${getTheApp} ${navDrawerOpen ? 'hide' : ''}`}
            >
              Get the App
            </a>

            <Hamburger
              navDrawerOpen={navDrawerOpen}
              toggleNavDrawer={toggleNavDrawer}
            />
          </div>
        </div>
        <div
          id="navDrawer"
          ref={navDrawerRef}
          aria-label="All Pages"
          aria-expanded="false"
        >
          <NavDrawer
            navDrawerOpen={navDrawerOpen}
            toggleNavDrawer={toggleNavDrawer}
            setNavDrawerOpen={setNavDrawerOpen}
            sections={sections}
            logo={free_equal_logo}
          />
        </div>
      </nav>
    </header>
  );
};

export default Header;
