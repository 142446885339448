// extracted by mini-css-extract-plugin
export var heroWrapper = "Hero-module--heroWrapper--KU7Pu";
export var partContainer = "Hero-module--partContainer--6ipyx";
export var partCircle = "Hero-module--partCircle--3BegH";
export var partNumber = "Hero-module--partNumber--32GfU";
export var titleWrapper = "Hero-module--titleWrapper--1auYw";
export var titleOpacity = "Hero-module--titleOpacity--2mDjh";
export var accentBlock = "Hero-module--accentBlock--2BSni";
export var ledeWrapper = "Hero-module--ledeWrapper--1dzdi";
export var textureImg = "Hero-module--textureImg--1PoPC";
export var paper = "Hero-module--paper--2GZaJ";
export var illustrationFigure = "Hero-module--illustrationFigure--2skfT";
export var illustrationWrapper = "Hero-module--illustrationWrapper--2Wl6m";
export var illustrationClass = "Hero-module--illustrationClass--2wEmO";
export var captionWrapper = "Hero-module--captionWrapper--2goGS";