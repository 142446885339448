import { Link } from 'gatsby';
import parse from 'html-react-parser';
import React from 'react';
import SmallRedCircle from '../../icons/SmallRedCircle';
import {
  exploreCircle,
  exploreContainer,
  pageContainer,
  pageDesc,
  pageLink,
  pageTitle,
  tiltLeft,
  tiltRight,
} from './PageLink.module.scss';

const PageLink = ({
  slug,
  title,
  summary,
  slugText,
  accent,
  theme,
  isEven,
  headerText,
}) => {
  return (
    <div className={`${pageContainer} bg${theme}`}>
      <div
        className={`varColor${accent} ${pageTitle} ${
          isEven ? tiltLeft : tiltRight
        }`}
      >
        {headerText && (
          <h2 className={`handLarge strong varColor${accent}`}>
            {headerText} <span className={`srOnly`}>Chapter</span>
          </h2>
        )}
        <h3 className={`textColorDune85`}>
          <Link to={`/${slug}/`}>{title}</Link>
        </h3>
      </div>
      <div className={`pageDescription ${pageDesc}`}>
        {summary && parse(summary)}
      </div>

      <div className={`${exploreContainer}`}>
        <div className={`varColor${accent} ${exploreCircle}`}>
          <SmallRedCircle />
        </div>
        <Link
          to={`/${slug}/`}
          className={`${pageLink} varColor${accent} exploreCircleText`}
        >
          {slugText}
        </Link>
      </div>
    </div>
  );
};

export default PageLink;
