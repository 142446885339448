// extracted by mini-css-extract-plugin
export var menuWrapper = "NavDrawer-module--menuWrapper--3CXKp";
export var show = "NavDrawer-module--show--2gPIH";
export var menuItems = "NavDrawer-module--menuItems--3gQEa";
export var mainSections = "NavDrawer-module--mainSections--1m-cA";
export var sectionsContent = "NavDrawer-module--sectionsContent--2sO3v";
export var navDrawerBg = "NavDrawer-module--navDrawerBg--2Iwws";
export var logoNavDrawer = "NavDrawer-module--logoNavDrawer--1Alk_";
export var homeLink = "NavDrawer-module--homeLink--3kQQQ";
export var getTheApp = "NavDrawer-module--getTheApp--3WgpW";
export var svgWrapper = "NavDrawer-module--svgWrapper--1yQZa";