import gsap from 'gsap';
import React, { useEffect, useRef } from 'react';
import { hamburger, open } from './Hamburger.module.scss';

const Hamburger = ({ navDrawerOpen, toggleNavDrawer }) => {
  const buttonTlRef = useRef(null);
  const hamburgerRef = useRef(null);

  // Setup the open/close animation
  useEffect(() => {
    const ref = gsap.utils.selector(hamburgerRef.current);
    gsap.set([ref('#hb-top'), ref('#hb-bottom')], { opacity: 1 });
    gsap.set([ref('#hb-mid1'), ref('#hb-mid2')], {
      transformOrigin: 'center center',
    });
    buttonTlRef.current = gsap
      .timeline({ paused: true })
      .to([ref('#hb-top'), ref('#hb-bottom')], { opacity: 0, duration: 0.3 })
      .to(ref('#hb-mid1'), { rotation: '45_cw', duration: 0.3 }, 0)
      .to(ref('#hb-mid2'), { rotation: '315_ccw', duration: 0.3 }, 0);
  }, []);

  // Play the open/close animation
  useEffect(() => {
    if (navDrawerOpen) {
      buttonTlRef.current.play();
    } else {
      buttonTlRef.current.reverse();
    }
  }, [navDrawerOpen]);

  return (
    <button
      type="button"
      className={`${hamburger} ${navDrawerOpen ? open : ''}`}
      aria-label={navDrawerOpen ? 'Close Menu' : 'Open Menu'}
      aria-haspopup="true"
      onClick={toggleNavDrawer}
      ref={hamburgerRef}
    >
      <svg viewBox="0 0 48 45" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect id="hb-top" x="8" y="30" width="32" height="3" fill="#252522" />
        <rect id="hb-mid1" x="8" y="21" width="32" height="3" fill="#252522" />
        <rect id="hb-mid2" x="8" y="21" width="32" height="3" fill="#252522" />
        <rect
          id="hb-bottom"
          x="8"
          y="12"
          width="32"
          height="3"
          fill="#252522"
        />
      </svg>
    </button>
  );
};

export default Hamburger;
