import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import parse from 'html-react-parser';
import React, { Fragment } from 'react';
import SmallRedCircle from '../../icons/SmallRedCircle';
import SvgInliner from '../global/SvgInliner';
import {
  accentBlock,
  captionWrapper,
  heroWrapper,
  illustrationClass,
  illustrationFigure,
  illustrationWrapper,
  ledeWrapper,
  paper,
  partCircle,
  partContainer,
  partNumber,
  textureImg,
  titleOpacity,
  titleWrapper,
} from './Hero.module.scss';
gsap.registerPlugin(ScrollTrigger);

const Hero = ({
  title,
  subtitle,
  part,
  titleSvg,
  texture,
  lede,
  illustration,
  illustrationCaption,
  mask,
  color,
}) => {
  if (color === null || color === undefined)
    color = { accent: 'Lemon', theme: 'Light' };
  const { accent, theme } = color;

  return (
    <div className={`bg${theme} ${heroWrapper} ${illustration ? '' : 'pb160'}`}>
      <div className={`${partContainer} `}>
        {part && (
          <Fragment>
            <div className={` ${partCircle}`}>
              <SmallRedCircle />
            </div>
            <span className={`handSmall ${partNumber}`}>{part}</span>
          </Fragment>
        )}
      </div>
      <div className={`${accentBlock} varColor${accent} varBgColor`} />
      <div className={`${titleWrapper}`}>
        <h1 className={`srOnly`}>{title}</h1>
        {titleSvg && (
          <SvgInliner
            svgContent={titleSvg.localFile?.svgData}
            className={`${titleOpacity}`}
          />
        )}
        <p className={`h3 textCenter textColorDune85 pt24 posRel`}>
          {subtitle}
        </p>
        {lede && (
          <div className={`lede textColorDune85 strong ${ledeWrapper} `}>
            {parse(lede)}
          </div>
        )}
      </div>
      {texture && (
        <GatsbyImage
          image={getImage(texture.localFile)}
          alt={''}
          className={`${textureImg}`}
          imgClassName={`multiply`}
        />
      )}
      <div className={`linedPaper ${paper}`}></div>
      <figure className={`${illustrationFigure}`}>
        {illustration && (
          <GatsbyImage
            image={getImage(illustration.localFile)}
            alt={''}
            className={`${illustrationWrapper}`}
            imgClassName={`${illustrationClass}`}
          />
        )}

        {illustrationCaption && (
          <figcaption className={`imageCredit ${captionWrapper}`}>
            {parse(illustrationCaption)}
          </figcaption>
        )}
      </figure>
    </div>
  );
};

export default Hero;
